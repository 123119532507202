import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import { header } from "../../assets";
import NavMenu from "../NavMenu/NavMenu";
import "./Header.scss";

export interface IHeaderProps {
  className?: string;
}

const Header = (props: IHeaderProps) => {
  const { className } = props;

  const navigateToInstagram = () => {
    window.open(
      "https://instagram.com/themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const navigateToTikTok = () => {
    window.open(
      "https://tiktok.com/@themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const navigateToYoutube = () => {
    window.open(
      "https://youtube.com/themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className={className}>
      <div className="socials">
        <FaInstagram
          size={24}
          className="link px-2"
          onClick={navigateToInstagram}
        />
        <FaTiktok size={24} className="link px-2" onClick={navigateToTikTok} />
        <FaYoutube
          size={24}
          className="link px-2"
          onClick={navigateToYoutube}
        />
      </div>
      <Link to="/">
        <img src={header} className="logo" alt="Theme Park Daze Logo" />
      </Link>
      <NavMenu className={"nav-menu"} />
    </div>
  );
};

export default Header;
