import { NavLink } from "react-router-dom";
import "./NavMenu.scss";

export interface INavMenuProps {
  className?: string;
}

const NavMenu = (props: INavMenuProps) => {
  const { className } = props;

  return (
    <div className={className}>
      <NavLink
        to={"/"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        Home
      </NavLink>
      <NavLink
        to={"podcast"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        Podcast
      </NavLink>
      <NavLink
        to={"contact"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        Contact
      </NavLink>
      <NavLink
        to={"about"}
        className={(isActive) =>
          isActive.isActive ? "menu-link is-active" : "menu-link"
        }
      >
        About
      </NavLink>
    </div>
  );
};

export default NavMenu;
