import { DateTime } from "luxon";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import "./Footer.scss";

export interface IFooterProps {
  className?: string;
}

const Footer = (props: IFooterProps) => {
  const { className } = props;

  const currentYear = DateTime.now().year;

  const navigateToInstagram = () => {
    window.open(
      "https://instagram.com/themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const navigateToTikTok = () => {
    window.open(
      "https://tiktok.com/@themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const navigateToYoutube = () => {
    window.open(
      "https://youtube.com/themeparkdaze",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className={className}>
      <span className="px-2">
        Copyright © {currentYear} ThemeParkDaze. All rights reserved. |
      </span>
      <FaInstagram className="link px-2" onClick={navigateToInstagram} />
      <FaTiktok className="link px-2" onClick={navigateToTikTok} />
      <FaYoutube className="link px-2" onClick={navigateToYoutube} />
    </div>
  );
};

export default Footer;
