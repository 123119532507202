import { BrowserRouter } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./App.scss";
import AppRoutes from "./Routes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header className={"header"} />
        <div className="main-app-body">
          <AppRoutes />
          <Footer className={"footer"} />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
