import { lazy } from "react";
import { IRouteSetup } from "./Routes";

const Home = lazy(() => import("../home/Home"));
const About = lazy(() => import("../about/About"));
const Socials = lazy(() => import("../socials/Socials"));
const Contact = lazy(() => import("../contact/Contact"));
const Podcast = lazy(() => import("../podcast/Podcast"));
const EpisodeDetail = lazy(() => import("../podcast/EpisodeDetail"));

export const HomeRoute: IRouteSetup = {
  Component: Home,
  path: "/",
};

export const PodcastRoute: IRouteSetup = {
  Component: Podcast,
  path: "/podcast",
};

export const AboutRoute: IRouteSetup = {
  Component: About,
  path: "/about",
};

export const SocialsRoute: IRouteSetup = {
  Component: Socials,
  path: "/socials",
};

export const ContactRoute: IRouteSetup = {
  Component: Contact,
  path: "/contact",
};

export const EpisodeDetailRoute: IRouteSetup = {
  Component: EpisodeDetail,
  path: "/podcast/episode/:episodeId",
};

export const routesConfigs: IRouteSetup[] = [
  HomeRoute,
  PodcastRoute,
  AboutRoute,
  SocialsRoute,
  ContactRoute,
  EpisodeDetailRoute,
];
